// Will be rewritten on build
const GLOBAL_ENV_TYPE = "h";
const SCOPE_ENVIRONMENT = "portal";
const APP_ID = "e8972f02-274b-4745-945d-7875bc21f763";
const ADMIN_CONSENT_URL = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=e8972f02-274b-4745-945d-7875bc21f763&scope=.default openid profile offline_access&redirect_uri=https%3A%2F%2Fportal.ironstoneit.com%2Fdashboard&response_mode=fragment&response_type=code&prompt=consent";
class AuthConfig {
	#ADMIN_CONSENT_URL;
	#ENVIRONMENT_TYPE;
	#DOMAIN;
	#DATA_LAKE_API_ENDPOINTS_TOP_URL;
	#BILLING_API_ENDPOINTS_TOP_URL;
	#MS_GRAPH_API_ENDPOINTS_TOP_URL;
	#PARTNER_CENTER_API_ENDPOINTS_TOP_URL;
	#PAGE_API_ENDPOINTS_TOP_URL;
	#FRESHSERVICE_API_ENDPOINTS_TOP_URL;
	#HARDWARE_API_ENDPOINTS_TOP_URL;
	#FUNCTION_API_ENDPOINTS_TOP_URL;
	#REPORTS_API_ENDPOINTS_TOP_URL;
	#SCHEDULER_API_ENDPOINTS_TOP_URL;
	#OCP_SUBSCRIPTION_KEY = "93d6890ae6c545e68eb06ccf87971e11";

	constructor(tenantId) {
		// If you want to run locally, set URL to `http:localhost:{port number}` for the given service
		this.tenantId = tenantId;
		this.#ADMIN_CONSENT_URL = ADMIN_CONSENT_URL;
		this.#ENVIRONMENT_TYPE = GLOBAL_ENV_TYPE;
		this.#DOMAIN = `https://ist-${this.#ENVIRONMENT_TYPE}-euw-portal-apim.azure-api.net`;
		this.#DATA_LAKE_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/datalake/v1`;
		this.#BILLING_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/billing/v1`;
		this.#MS_GRAPH_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/msgraph/v1`;
		this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/freshservice/v1`;
		this.#PARTNER_CENTER_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/partnercenter/v1`;
		this.#HARDWARE_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/hardware/v1`;
		this.#SCHEDULER_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/scheduler/v1`;
		this.#FUNCTION_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/function`;
		this.#REPORTS_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/reports`;
		this.#PAGE_API_ENDPOINTS_TOP_URL = `${this.#DOMAIN}/api/page`;
	}

	// The tenants which are allowed to login to to the portal
	get ALLOWED_TENANTS() {
		return [
			"fdc1facf-074a-4bd1-b388-07878251bf81", // ??
			"3eaaf1d3-6f9e-40fd-b7e9-60b45e55e125", // Ironstone IT
			"ce2eb283-da8c-47d3-a2ef-7701be6058fe", // CSP Ironstone
			"dff04b02-c61b-4f4f-8cd5-2b9c6b379752", // Test Ironstone
			"10796579-20b7-40b2-8396-f700793242f0", // Ironstone Demo Business Premium
			"1864b58b-bf2c-46c1-9f1a-3a684263fe01", // Ironstone Demo E3
			"ecfad119-7dd0-4d42-ba57-8e20dd34fa8d", // Ironstone Demo E5 Premium
			"efa501ec-3311-436f-98a1-3e31aae15da9", // Ironstone External Demo

			// Regular customers
			"767d2bfd-f087-41e4-9539-1c42896e80ab", "8f6078fe-287b-4c4d-b1b7-793bc6ead9e4", "ef11e498-2944-4fd4-afcb-0e0e627e4f52",
			"6d4b25d1-038f-4960-a878-f2b8c4620fd0", "74dcd7c8-7a0f-447b-84d6-9c74f5990a06", "1042fa82-e1c7-40a8-9c61-a7831ef3f10a",
			"a3483869-2e6d-41e0-9f98-e5de27eb9b56", "59217953-defa-441f-964d-c1c33f1883c6", "48617d73-72c5-4640-93a2-4e8ffb6bcc25",
			"0b80a902-976d-4f63-84d0-0d5f3dacd0d7", "1a0c438a-bb9e-46ba-9e25-8646ba0c3701", "48c1e73f-2236-4abd-a141-07f7c3ae69e2",
			"cf9df479-c17a-4581-9e8b-d167d14f6446", "eb1706d5-eeae-4c2e-af6b-243e33cbf7a7", "10cff2ca-2133-441f-a821-7c9422e11729",
			"30a5519c-90ca-470c-bfa7-0f3adb1b4a4c", "b518c0db-8be6-4b7f-bf4e-2d8cbbd75b33", "37a49a16-b27c-4909-9891-338b46379b9d",
			"1262e606-7ed9-4834-aafa-86235e3be696", "c3d0112c-466d-4f31-b296-c87ddc860249", "15a81ae1-40e0-4eeb-8bb7-438db81d7aa1",
			"604b05be-690f-4855-b017-19b7bd7e1cc5", "8f02fce7-3c60-447d-8a55-f962c0317b4b", "d4f0a832-620e-4f27-9ad9-f6d993fefb50",
			"ba258d44-494f-4d5d-a4ea-6484d8b879a1", "13618721-46eb-4763-a2e6-616423735408", "b734fc0c-f76f-422d-a418-1f9bd62cde96",
			"c0c88579-fefa-4e74-8875-162f7238f471", "e6158854-05c2-43c9-a3b2-fd0e4eddc9db", "fc8bda4d-c1a4-4400-8121-df4a7ddf1e96",
			"8465d729-6ebd-469b-863b-e2cfe484c592", "2f46c919-7c11-4465-84b2-e354fb809979", "02ac6765-f585-410e-be6f-0a717ad9c410",
			"0c9ab3e0-c980-4adb-bf80-101d5c0c76bc", "f649854d-898f-4502-b4dd-52b8df5ae374", "0dee8fe3-dc7c-4033-b37c-7c4871beaf77",
			"e3f99559-2ef3-482b-8ad9-dd9a2cfef44c", "1d3c5897-0611-40d0-95f1-f7de5eecbdbc", "31a54709-2566-4bf6-88ad-05059c345ec3",
			"7a9d2728-0464-4316-b98d-60976f37e479", "191f5413-7b05-4d3a-8501-84af9b56928f", "b460c35d-148f-4708-a8c9-d0cb7605ab7b",
			"da8f93df-6af3-4369-a3d3-ab4f74cfa51c", "e17fe564-356d-4667-8f82-7a4eef473405", "5633285f-092f-4734-a031-449f0e1030ad",
			"dbfa6eb7-980a-4060-acff-f4ef561e65aa", "fd57e0f5-44e9-41b1-8cbf-7575db70db2f", "4f9b015f-aad5-4697-a05e-7290cda687ed",
			"3abbb2e8-5ee7-4e75-ac64-5eb6f273e46c", "f9403dfb-9dad-4bf3-b6de-36f05a10f75c", "65cba4c7-44f1-406e-847d-c1eeb5c7a48d",
			"01920020-8bac-4e25-a10e-d811518fe42e", "b15a587d-acc9-4644-aa51-b56dee85c304", "42caec1d-ecbe-4c9f-8735-4354eefb7fe3",
			"5187d05c-8511-4e3d-836f-7f96e439abb6", "a6e177ed-014c-43fa-b395-63aaf1204e45", "6dfa6b7e-7166-4791-b61a-832c9b9fadd0",
			"5b2e42e0-d64d-49d0-9019-6b657a5c05c7", "dc11ab18-5780-4dde-94fc-195fa7d5e013", "e838e01f-75cf-41ed-922a-53d1d890b560",
			"377b97bf-6eef-4258-91a1-a29ca8c5a9ea", "57455aad-0b0f-4c11-9c54-6cd01f7250eb", "28253fc2-7430-42be-a4ad-a74aed871523",
			"61033493-6dd0-4c07-8e25-0efbad59ff59", "8232ca76-4c5b-4c51-91c5-dac80f367abb", "10740139-743f-46a6-a8b7-58fc181c7096",
			"97aab738-df9b-4dd3-9074-349df460240a", "1a346c4e-46ee-4fb3-8a3a-de2ee0ed932b", "1b448ba7-cb63-4a97-9614-dd1cb451e621",
			"338f0a13-2b29-4714-9527-bc1e8b8d0162", "7f613c61-06d4-4a64-90ba-979121d6aca0", "f482e803-ebcc-46ce-8971-a61020804d8a",
			"22fa6cc6-f5e8-4065-8b67-080c5ee7eaa4", "0d109c67-df6a-4e40-a46a-c997d61e35b1", "e9ac0bc7-2a36-40a4-b1ae-895762b285f6",
			"c3292e41-2497-4c2e-9148-5b93e234f504", "b5a468ec-1980-453f-be47-fbf4e1366ff6", "336b2005-cfad-4351-859b-1a440b73af76",
			"ed55d540-5ccf-4a87-a0b4-e6edaf95fc55", "12477c6f-65c2-4a5f-a492-a05a69a73c09", "1d614992-1dbe-4f9a-9a68-1b6b5a485249",
			"87ce32a7-d259-45fa-b8de-70d018841fc5", "07e52f36-f318-4b02-b257-25d7aa4e63d8", "4617d72b-7418-4eb6-bbb1-1889ed588894",
			"4413f8ec-be2f-43c0-83de-dab13f6ea059", "40378fec-f163-4158-a5cb-41a8122c4c73", "0ff6c3eb-f625-4d7e-baa9-2cd1a39e359f",
			"6098724f-f53d-42d1-9d65-20cae598af29", "b6bc280b-a576-4461-9185-97bca6f7068f", "0f2149f8-f347-4aaa-831c-22c3a9e26bc4",
			"f48f3686-91a4-4a60-8216-0c3a5f878b40", "1a138a3f-a68c-490f-9b1a-98a9a8fd4a84", "6032b458-0f05-485a-b04c-f2cf57aab816",
			"0043064f-45db-4915-bb0c-c67631b797dd", "3b59e22d-f1d0-408a-b68f-52fec1afd429", "10ba26f2-b546-4ae0-ae93-bf7154619d12",
			"f5862a42-f1f2-4f3b-a043-dc99e4c07d84", "4fa98216-e4ff-441a-8e0b-5465ff8c0bfb", "4e9006de-484e-463a-9489-d21a15484b0a",
			"d02c7ca1-fcfc-4ae1-a1ba-adf6ca1fd969", "61c7300d-bd7c-4dd2-99c9-a4b665f543e9", "dd63ef50-0da2-43ea-8cbc-8b045d3de1e2",
			"4beff69f-ae29-49e6-b2a5-42c897f035ac", "1b47f7e2-b7a1-43f4-9b7a-e39cdaaaf5aa", "b06acf59-afbf-4e71-876b-798732b7c211",
			"b0c0156d-1e4e-44f1-9bcc-714a68300758", "1b53b905-af7b-4153-9c1d-69c5e9aa3cb5", "ef80dfcd-61b9-4609-ac65-f7bf18bcd968",
			"5f92765f-23af-4383-8724-16b0db42ffd7", "7a9e890e-9ba5-4727-9002-6c84d081260d", "cc9ef7fa-3227-4bc8-90c3-7f7568108b09",
			"20b03bb4-3a5f-4018-8819-ad773a8d995c", "4cf54203-187c-44fa-9f45-88d608144c48", "9b730ff0-2826-466c-b711-0862e5e82edd",
			"0891cae4-7edd-4e4a-922d-1a1849933457", "bf151a1a-2191-4aee-9ae1-7ee08e942c5f", "d413649b-ed7b-4830-a854-3833d32b4d57",
			"1ca6cfea-8792-4869-9264-6945574342c3", "ceccf8ba-2715-4d66-8ab3-7fe9a3220fb5", "9bcad0a7-b9ab-4a75-b321-5c55972f0032",
			"8ee56b0e-2219-4c88-a0fa-f03e9ef46556", "799b9439-3d89-4228-819a-1f32ff2e5a75", "5f32a307-af2e-4ffc-9ecf-bc14f1d8598c",
			"7e57dd27-d191-4d5c-9d84-547e6cccb1b9", "5416efc5-703f-4ead-8a54-186bfe6466d1", "454f5dbd-5e6c-4e0e-acf3-146198d7a95a",
			"d14da004-a096-4c7b-9e2a-0f40b73541a1", "90d5b8cf-aca5-4165-b31c-4cf891baa750", "53c3c12a-fdf3-4945-aba0-c2568bfe2254",
			"27d715e8-27cb-445a-8496-ff8d412ea261", "a6926c31-38e6-495e-8183-4f12d03cc300", "c475547b-8389-49bd-8847-3c0ef0b9e181",
			"4766d163-bbeb-4712-9187-0e4e7c674829", "f56f5acf-4bbc-41d8-b709-c8ce12dadaaf", "b509b977-2012-4f76-92d1-32d3b96e180c",
			"429d52df-3073-407b-bda6-bfb4104dbb72", "b4a6c328-10ce-4039-9601-60ee936e5ea7", "38c2e927-2d18-4a6b-ae7c-74fc2087c353",
			"945675d2-40a2-4335-bea4-f9140320b299", "451d6a19-0d85-4fa0-ac24-8b229d9e2130", "ae87ad22-4d06-495a-ad38-c35267608a57",
			"72b1158f-3b7d-45ed-910c-ccdb16c6cb9d", "470ebac9-3bf1-4557-b997-2f925bc3291a", "ae467e94-6af6-4bd3-9bac-2a020c3ae90f",
			"429d52df-3073-407b-bda6-bfb4104dbb72", "c559e7f8-cbfd-4fdb-8a24-f291c24d70dd", "de5867fc-ca89-489b-9056-2e78e16b58ab",
			"977b8b64-9551-42a8-9067-5bc7811697f6", "d099f035-8914-4fb0-8fe6-92b1fb3c74a4", "69e5cf9f-2b65-4851-a3d1-e096874d22c6",
			"779d44a4-83fa-468e-b7d0-2875357dc7bd", "cb6228a1-8648-4033-bced-c3b97aac6222", "ed486ed8-16c8-40a1-b30a-0c949030bdc1",
			"bee70e5d-242a-4aad-8d84-2af2cf1eb10c", "6746d47f-f067-410b-ac88-475d6c7fd3f2", "0a19e9dd-b128-4c87-8aa7-8ccf927d684a",
			"48617d73-72c5-4640-93a2-4e8ffb6bcc25", "53e56282-8ca9-4de7-97c4-2c9046ff5e6e", "ccc1bb47-cfa7-455d-9ee8-096638eacda6", 
			"daa063a3-5630-4a62-9cdc-38036aad8a6a", "5ca93399-1184-430d-88a8-107721ef7b66", "36c63375-0df4-4ea9-9553-c2e17f3fc13f", 
			"dd5a9c98-67ff-410e-bfe7-79ccbbf4234e", "d07b084f-ad6d-471c-8b98-451456a799df"
		];
	}

	get ADMIN_CONSENT_URL() {
		return this.#ADMIN_CONSENT_URL;
	}

	// Add here the endpoints for MS Graph API services you would like to use.
	get GRAPH_CONFIG() {
		return {
			GRAPH_ME_PHOTO: () => "https://graph.microsoft.com/beta/me/photos/240x240/$value",
		};
	}

	get SCHEDULER_API_ENDPOINTS() {
		return {
			LICENSE_ACTIONS: () => `${this.#SCHEDULER_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId}/licenseActions`,
			EXECUTE_LICENSE_ACTION: (licenseActionId) => `${this.#SCHEDULER_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId}/execute-scheduledlicenseaction/${licenseActionId}/execute`,
			EXECUTE_ALL_LICENSE_ACTIONS: () => `${this.#SCHEDULER_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId}/execute-scheduledlicenseactions`,
		};
	}



	get DATA_LAKE_API_ENDPOINTS() {
		return {
			GET_AGGREGATED_DATA: () =>
				`${this.#DATA_LAKE_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId
				}/aggregateddata`,
			GET_CUSTOMER_DATA: (folderPath) =>
				`${this.#DATA_LAKE_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId
				}/customerData/${folderPath}`,
			POST_CUSTOMER_DATA: (folderPath) =>
				`${this.#DATA_LAKE_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId
				}/customerData/${folderPath}?Overwrite=True`,
			GET_IDENTITY_REPORT_FILENAMES: () =>
				`${this.#DATA_LAKE_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId
				}/folderContent/CloudReports__Reports__Identity__Markdown`,
			GET_FOLDER_CONTENT: (folderPath) =>
				`${this.#DATA_LAKE_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId
				}/folderContent/${folderPath}`,
			GET_LICENSE_NAMES: () =>
				`${this.#DATA_LAKE_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId
				}/customerData/Common__MicrosoftFiles__licenseNames.json`,
		};
	}

	get BILLING_API_ENDPOINTS() {
		return {
			GET_AZURE_BILLING: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/azure`,
			GET_OFFICE365_BILLING: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/office365`,
			GET_CONSULTANCY_COSTS: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/consultancy`,
			GET_YOUR_EMPLOYEES_COSTS: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/yourEmployees`,
			GET_YOUR_IT_SYSTEMS_COSTS: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/yourItSystems`,
			GET_ALL_COSTS: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/totalCost`,
			GET_SPECIFIC_PERIOD_ALL_COSTS: () =>
				`${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/totalCost/download`,
			GET_ALL_INVOICES: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/invoices`,
			GET_MARKETPLACE_ITEMS_COST: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/marketplaceItems`,
			GET_RESERVED_INSTANCES_COST: () => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/reservedInstances`,
			GET_INVOICE: (invoiceId) => `${this.#BILLING_API_ENDPOINTS_TOP_URL}/${this.tenantId}/invoices/${invoiceId}`,
		};
	}

	get MS_GRAPH_API_ENDPOINTS() {
		return {
			CHECK_MANAGEMENT_ROOT_GROUP_ACCESS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId
				}/rootManagementGroupAccess`,
			SET_MANAGEMENT_ROOT_GROUP_ACCESS: (userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId
				}/${userId}/rootManagementGroupAccess`,
			GET_USERS: () => `${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users`,
			GET_USER: (userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users/${userId}/info`,
			GET_USER_DEVICES: (userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users/${userId}/devices`,
			MANAGER: (userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users/${userId}/manager`,
			GET_MANAGER_STRUCTURE: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/managerStructure`,
			POST_USER: () => `${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/user`,
			POST_COLLABORATION_REQUEST: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/collaborationRequest`,
			POST_GUEST_INVITATION_IRONSTONE_B2B_TENANT: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/ironstoneB2B/invite`,
			UPDATE_USER: (userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users/${userId}`,
			GET_SUBSCRIBED_SKUS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/subscribedSkus`,
			GET_GROUPS: () => `${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/groups`,
			GET_LICENSE_GROUPS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/licenseGroups`,
			ADD_GROUP_MEMBER: (groupId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/groups/${groupId}/members`,
			REMOVE_GROUP_MEMBER: (groupId, userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/groups/${groupId}/members/${userId}/`,
			REMOVE_LICENSES_FROM_USERS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users/licenses`,
			ASSIGN_LICENSES: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/licenseAssignment`,
			GET_LICENSE_ASSIGNMENT_ERRORS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/licenseAssignmentErrors`,
			GET_LICENSE_ASSIGNMENT_ERRORS_FOR_USER: (id) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/${id}/licenseAssignmentErrors`,
			GET_GROUP_LIFECYCLE_POLICIES: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/groupLifecyclePolicies`,
			SET_GROUP_LIFECYCLE_POLICIES: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/groupLifecyclePolicies`,
			REPROCESS_LICENSE_ASSIGNMENT: (userId) =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users/${userId}/reprocessLicenseAssignment`,
			GET_MAIL_DOMAINS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/mailDomains`,
			GET_DEPARTMENTS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/departments`,
			GET_COUNTRIES: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/countries`,
			SEND_MAIL: () => `${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/mail`,
			SEND_SMS: () => `${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/sms`,
			GET_ONE_TIME_PASSWORD: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/otp`,
			GET_APP_ROLES_ASSIGNMENTS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/apps/${APP_ID}/appRolesAssignedTo`,
			UPDATE_APP_ROLES_ASSIGNMENTS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/apps/${APP_ID}/appRolesAssignedTo`,
			GET_SERVICE_PRINCIPAL: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/servicePrincipals`,
			DEVICES: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/devices`,
			DEVICE_COMPLIANCE_REPORT: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/deviceReport`,
			DEVICE_COMPLIANCE_REPORT_STATUS: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/deviceReportStatus`,
			GET_BRANDING: () =>
				`${this.#MS_GRAPH_API_ENDPOINTS_TOP_URL}/${this.tenantId}/branding`,
		};
	}

	get FRESHSERVICE_API_ENDPOINTS() {
		return {
			GET_CUSTOMER_INFO: () =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/customerInfo`,
			UPDATE_CUSTOMER_INFO: (departmentId) =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/${departmentId}/customerInfo`,
			TICKETS: () => `${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/tickets`,
			POST_REQUESTER: () =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/requester`,
			UPDATE_TICKET: (ticketId) =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/tickets/${ticketId}`,
			REPLY_TO_TICKET: (ticketId) =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId
				}/tickets/${ticketId}/replies`,
			GET_SECURITY_INCIDENTS: (source) =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/${source}/incidents`,
			GET_SERVICE_STATUSES: () =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/serviceStatuses`,
			GET_REQUESTERS: () =>
				`${this.#FRESHSERVICE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/requesters`,
		};
	}

	get HARDWARE_API_ENDPOINTS() {
		return {
			GET_ORDERINFO: () =>
				`${this.#HARDWARE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/orderInfo`,
			CREATE_ORDER: (userId) =>
				`${this.#HARDWARE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/${userId}/orders`,
			CREATE_ORDER_REQUEST: (userId) =>
				`${this.#HARDWARE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/${userId}/orderRequests`,
			GET_PRODUCTS: () => `${this.#HARDWARE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/products`,
			GET_PRODUCTS_V2: () =>
				`${this.#HARDWARE_API_ENDPOINTS_TOP_URL}/v2/${this.tenantId}/products`,
		};
	}

	get FUNCTION_API_ENDPOINTS() {
		return {
			GET_COMPARE_APP_PERMISSIONS: () =>
				`${this.#FUNCTION_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId
				}/compareAppPermissions `,
			GET_LICENSE_RECOMMENDATIONS: () =>
				`${this.#FUNCTION_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId
				}/recommendationsFile `,
			LIST_CUSTOMER_DOCUMENTS: () => 
				`${this.#FUNCTION_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId}/ListConfluenceCustomerAttachments`,
			GET_CUSTOMER_DOCUMENT: (attachmentId) =>
				`${this.#FUNCTION_API_ENDPOINTS_TOP_URL}/companies/${this.tenantId}/GetConfluenceCustomerAttachments/${attachmentId}`,
		}
	}

	get REPORTS_API_ENDPOINTS() {
		return {
			CREATE_AGGREGATED_DATA: () =>
				`${this.#REPORTS_API_ENDPOINTS_TOP_URL}/${this.tenantId}/aggregatedData/generate`,
			CREATE_IDENTITY_REPORT: () =>
				`${this.#REPORTS_API_ENDPOINTS_TOP_URL}/${this.tenantId}/identity/generate`,
			CREATE_AZURE_BILLINGDATA: () =>
				`${this.#REPORTS_API_ENDPOINTS_TOP_URL}/${this.tenantId}/azurebilling/generate`,
			CREATE_OFFICE365_BILLINGDATA: () =>
				`${this.#REPORTS_API_ENDPOINTS_TOP_URL}/${this.tenantId}/officebilling/generate`,
			CREATE_TIMELY_HOURS_REPORT: () =>
				`${this.#REPORTS_API_ENDPOINTS_TOP_URL}/${this.tenantId}/timelyhours/generate`,
			CREATE_MARKETPLACE_ITEMS_BILLINGDATA: () =>
				`${this.#REPORTS_API_ENDPOINTS_TOP_URL}/${this.tenantId}/marketplaceitems/generate`,
			CREATE_RESERVED_INSTANCES_BILLINGDATA: () => 
				`${this.#REPORTS_API_ENDPOINTS_TOP_URL}/${this.tenantId}/reservedinstances/generate`,
			CREATE_LICENSE_COST_RECOMMENDATIONS: () =>
				`${this.#REPORTS_API_ENDPOINTS_TOP_URL}/${this.tenantId}/licenceCostRecommendation/generate`,
		}
	}


	get PARTNER_CENTER_API_ENDPOINTS() {
		return {
			DELETE_USER: (userId) =>
				`${this.#PARTNER_CENTER_API_ENDPOINTS_TOP_URL}/${this.tenantId}/users/${userId}`,
			GET_SUBSCRIPTIONS: () =>
				`${this.#PARTNER_CENTER_API_ENDPOINTS_TOP_URL}/${this.tenantId}/subscriptions`,
			GET_SUBSCRIPTION: (subscriptionId) =>
				`${this.#PARTNER_CENTER_API_ENDPOINTS_TOP_URL}/${this.tenantId}/subscriptions/${subscriptionId}`,
			ADJUST_LICENSE_SUBSCRIPTION_QUANTITY: () =>
				`${this.#PARTNER_CENTER_API_ENDPOINTS_TOP_URL}/${this.tenantId
				}/subscriptions/quantity`,
		};
	}

	get PAGE_API_ENDPOINTS_TOP_URL() {
		return {
			PAGE: () => `${this.#PAGE_API_ENDPOINTS_TOP_URL}/${this.tenantId}/hit`,
		};
	}

	get OCP_APIM_SUBSCRIPTION_KEY() {
		return this.#OCP_SUBSCRIPTION_KEY;
	}
}

const MS_AL_CONFIG = {
	auth: {
		authority: `https://login.microsoftonline.com/common`,
		clientId: APP_ID,
		redirectUri: "/",
	},
	cache: {
		cacheLocation: "sessionStorage", // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
};

const config = {
	MS_AL_CONFIG,
	APP_ID,
	AUTHORITY: `api://${SCOPE_ENVIRONMENT}.ironstoneit.com`,
	AuthConfig,
};

export default config;
